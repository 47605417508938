// A base value used to responsively scale all typography, applied to the `<html>` element

$font-size-root: 16px !default;

// Font face
// Based on https://material.google.com/style/typography.html#typography-typeface

// N.B. Although some values are not used in this project
// we leave them here just in case they may be required by your project

$font-family-monospace:  'Roboto Mono', 'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', monospace !default;
$font-family-sans-serif: 'Roboto', system-ui, -apple-system, 'Segoe UI', 'Helvetica Neue', 'Arial', 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
// $font-family-serif:      'Roboto Slab', Georgia, 'Times New Roman', Times, serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$font-family-base:       $font-family-sans-serif !default;

$font-weight-medium:     500 !default;
$font-weight-bold:       $font-weight-medium !default;
$font-weight-bolder:     600 !default;
$font-weight-regular:    400 !default;
$font-weight-normal:     $font-weight-regular !default;
$font-weight-light:      300 !default;
$font-weight-lighter:    $font-weight-light !default;

$font-weight-base:       $font-weight-regular !default;

// Font styles
// Based on https://material.io/design/material-theming/implementing-your-theme.html#typography
// and https://material-components.github.io/material-components-web-catalog/#/component/typography

$font-size-base:            1rem !default;   // 16px

$font-size-headline-1:      ($font-size-base * 6) !default;        // 96px
$font-size-headline-2:      ($font-size-base * 3.75) !default;     // 60px
$font-size-headline-3:      ($font-size-base * 3) !default;        // 48px
$font-size-headline-4:      ($font-size-base * 2.125) !default;    // 34px
$font-size-headline-5:      ($font-size-base * 1.5) !default;      // 24px
$font-size-headline-6:      ($font-size-base * 1.25) !default;     // 20px
$font-size-subtitle-1:      $font-size-base !default;              // 16px
$font-size-subtitle-2:      ($font-size-base * 0.875) !default;    // 14px
$font-size-body-1:          $font-size-base !default;              // 16px
$font-size-body-2:          ($font-size-base * 0.875) !default;    // 14px
$font-size-caption:         ($font-size-base * 0.75) !default;     // 12px
$font-size-overline:        ($font-size-base * 0.75) !default;     // 12px

$font-weight-headline-1:     $font-weight-light !default;
$font-weight-headline-2:     $font-weight-light !default;
$font-weight-headline-3:     $font-weight-regular !default;
$font-weight-headline-4:     $font-weight-regular !default;
$font-weight-headline-5:     $font-weight-regular !default;
$font-weight-headline-6:     $font-weight-medium !default;
$font-weight-subtitle-1:     $font-weight-regular !default;
$font-weight-subtitle-2:     $font-weight-medium !default;
$font-weight-body-1:         $font-weight-regular !default;
$font-weight-body-2:         $font-weight-regular !default;
$font-weight-caption:        $font-weight-regular !default;
$font-weight-overline:       $font-weight-medium !default;

$letter-spacing-headline-1:  -0.015625em !default;
$letter-spacing-headline-2:  -0.0083333333em !default;
$letter-spacing-headline-3:  0 !default;
$letter-spacing-headline-4:  0.0073529412em !default;
$letter-spacing-headline-5:  0 !default;
$letter-spacing-headline-6:  0.0125em !default;
$letter-spacing-subtitle-1:  0.009375em !default;
$letter-spacing-subtitle-2:  0.0071428571em !default;
$letter-spacing-body-1:      0.03125em !default;
$letter-spacing-body-2:      0.0178571429em !default;
$letter-spacing-caption:     0.0333333333em !default;
$letter-spacing-overline:    0.1666666667em !default;

// Line height

$line-height-base:       1.5 !default;

$line-height-headline-1: 6rem !default;
$line-height-headline-2: 3.75rem !default;
$line-height-headline-3: 3.125rem !default;
$line-height-headline-4: 2.5rem !default;
$line-height-headline-5: 2rem !default;
$line-height-headline-6: 2rem !default;
$line-height-subtitle-1: 1.75rem !default;
$line-height-subtitle-2: 1.375rem !default;
$line-height-body-1:     1.5rem !default;
$line-height-body-2:     1.25rem !default;
$line-height-caption:    1.25rem !default;
$line-height-overline:   2rem !default;

// Material icons default size

$material-icon-size: (24 / strip-unit($font-size-root) * 1rem) !default;
