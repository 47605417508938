.textfield-box {
  %form-control {
    background-color: $textfield-box-bg;
    border-radius: $textfield-box-border-radius $textfield-box-border-radius 0 0;
    padding: $textfield-box-padding-y $textfield-box-padding-x calc(#{$textfield-box-padding-y} - #{$textfield-border-width});
    transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1); //addition TODO

    &::placeholder {
      color: $textfield-box-placeholder-color;
      opacity: 1; /* needed for Firefox */
    }

    &:hover {
      background-color: $textfield-box-bg-hover;
    }

    &:focus {
      background-color: $textfield-box-bg-focus;
    }
  }

  %form-select {
    padding-right: $textfield-select-bg-size;
  }

  %form-textarea {
    border-radius: $textfield-box-border-radius $textfield-box-border-radius 0 0;
    border-width: 0 0 $textfield-border-width;
    padding: $textfield-box-padding-y $textfield-box-padding-x calc(#{$textfield-box-padding-y} - #{$textfield-border-width});

    &:hover {
      border-color: $textfield-border-color-hover;
      //box-shadow: inset 0 ($textfield-border-width-hover * -1) 0 ($textfield-border-width * -1) $textfield-border-color-hover;
    }

    &:focus {
      border-color: $textfield-border-color-focus;
      box-shadow: inset 0 ($textfield-border-width-focus * -1) 0 ($textfield-border-width * -1) $textfield-border-color-focus;
    }
  }

  // Form control sizing

  textarea.form-control { // stylelint-disable-line
    min-height: $textfield-box-height;
  }

  .form-control[type='file'],
  .form-control-file {
    max-height: $textfield-box-height;
  }
}

// Size

.textfield-box-lg {
  %form-control {
    font-size: $textfield-font-size-lg;
    line-height: $textfield-line-height-lg;
    padding: $textfield-box-padding-y-lg $textfield-box-padding-x-lg calc(#{$textfield-box-padding-y-lg} - #{$textfield-border-width});
  }

  %form-textarea {
    padding: $textfield-box-padding-y-lg $textfield-box-padding-x-lg calc(#{$textfield-box-padding-y-lg} - #{$textfield-border-width});
  }

  // Form control sizing

  textarea.form-control { // stylelint-disable-line order/order, selector-no-qualifying-type
    min-height: $textfield-box-height-lg;
  }

  .custom-select {
    padding-right: $textfield-select-bg-size;
  }

  .form-control[type='file'],
  .form-control-file {
    max-height: $textfield-box-height-lg;
  }
}

.textfield-box-sm {
  %form-control {
    font-size: $textfield-font-size-sm;
    line-height: $textfield-line-height-sm;
    padding: $textfield-box-padding-y-sm $textfield-box-padding-x-sm calc(#{$textfield-box-padding-y-sm} - #{$textfield-border-width});
  }

  %form-textarea {
    padding: $textfield-box-padding-y-sm $textfield-box-padding-x-sm calc(#{$textfield-box-padding-y-sm} - #{$textfield-border-width});
  }

  // Form control sizing

  // stylelint-disable-next-line order/order, selector-no-qualifying-type
  textarea.form-control {
    min-height: $textfield-box-height-sm;
  }

  .custom-select {
    padding-right: $textfield-select-bg-size;
  }

  .form-control[type='file'],
  .form-control-file {
    max-height: $textfield-box-height-sm;
  }
}

// Ripple

@if ($enable-form-ripple) {
  .textfield-box.form-ripple-nolabel::before,
  .floating-label.textfield-box.form-ripple::before {
    top: calc(#{($textfield-box-height)} - #{(2*$textfield-border-width)});
  }

  .textfield-box-lg.form-ripple-nolabel::before,
  .floating-label-lg.textfield-box.form-ripple::before {
    top: calc(#{($textfield-box-height-lg)} - #{($textfield-border-width)});
  }

  .textfield-box-sm.form-ripple-nolabel::before,
  .floating-label-sm.textfield-box.form-ripple::before {
    top: calc(#{($textfield-box-height-sm)} - #{(2*$textfield-border-width)});
  }

  .textfield-box.form-ripple::before {
    top: calc(85px - 2px);
  }

  .textfield-box-lg.form-ripple::before {
    top: calc(98px - 2px);
  }

  .textfield-box-sm.form-ripple::before {
    top: calc(73px - 2px);
  }
}
