// Bootstrap functions
//
// Utility mixins and functions for evaluating source code across our variables, maps, and mixins.

// stylelint-disable at-rule-empty-line-before

// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;

  @each $key, $num in $map {
    @if $prev-num == null or unit($num) == '%' or unit($prev-num) == '%' {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// Starts at zero
// Used to ensure the min-width of the lowest breakpoint starts at 0.
@mixin _assert-starts-at-zero($map, $map-name: '$grid-breakpoints') {
  @if length($map) > 0 {
    $values: map-values($map);
    $first-value: nth($values, 1);

    @if $first-value != 0 {
      @warn 'First breakpoint in #{$map-name} must start at 0, but starts at #{$first-value}.';
    }
  }
}

// See https://codepen.io/kevinweber/pen/dXWoRw
//
// Requires the use of quotes around data URIs.

@function escape-svg($string) {
  @if str-index($string, 'data:image/svg+xml') {
    @each $char, $encoded in $escaped-characters {
      // Do not escape the url brackets
      @if str-index($string, 'url(') == 1 {
        $string: url('#{str-replace(str-slice($string, 6, -3), $char, $encoded)}');
      } @else {
        $string: str-replace($string, $char, $encoded);
      }
    }
  }

  @return $string;
}

// Color contrast
@function color-yiq($color) { // color-yiq($color, $dark: $yiq-text-dark, $light: $yiq-text-light) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= $yiq-contrasted-threshold) {
    @return $yiq-text-dark;
  } @else {
    @return $yiq-text-light;
  }
}

// Replace `$key` with `$replace` in `$content`
// Different from BS 4.4.1

@function str-replace($content, $key, $replace: '') {
  $index: str-index($content, $key);

  @if $index {
    @return str-slice($content, 1, $index - 1) + $replace + str-replace(str-slice($content, $index + str-length($key)), $key, $replace);
  } @else {
    @return $content;
  }
}

// Retrieve a colour

@function color($key: amber) {
  @return map-get($colors, $key);
}

@function theme-color($key: primary) {
  @return map-get(map-get($theme-colors, $key), color);
}

@function theme-color-dark($key: primary) {
  @return map-get(map-get($theme-colors, $key), dark);
}

@function theme-color-light($key: primary) {
  @return map-get(map-get($theme-colors, $key), light);
}

// Return valid calc
@function add($value1, $value2, $return-calc: true) {
  @if $value1 == null {
    @return $value2;
  }

  @if $value2 == null {
    @return $value1;
  }

  @if type-of($value1) == number and type-of($value2) == number and comparable($value1, $value2) {
    @return $value1 + $value2;
  }

  @return if($return-calc == true, calc(#{$value1} + #{$value2}), $value1 + unquote(' + ') + $value2);
}

@function subtract($value1, $value2, $return-calc: true) {
  @if $value1 == null and $value2 == null {
    @return null;
  }

  @if $value1 == null {
    @return -$value2;
  }

  @if $value2 == null {
    @return $value1;
  }

  @if type-of($value1) == number and type-of($value2) == number and comparable($value1, $value2) {
    @return $value1 - $value2;
  }

  @return if($return-calc == true, calc(#{$value1} - #{$value2}), $value1 + unquote(' - ') + $value2);
}

// Strip unit

@function strip-unit($val) {
  @return $val / ($val * 0 + 1);
}

// Use secondary color for selection controls/inputs

@function selection-theme-color() {
  @if $secondary-color-for-controls {
    @return theme-color(secondary);
  } @else {
    @return theme-color(primary);
  }
}

// RGBA to RGB
// Credits: https://makandracards.com/makandra/42500-sass-how-to-convert-an-rgba-color-to-its-rgb-look-alike

@function rgba-to-rgb($rgba, $background: #fff) {
  @return mix(rgb(red($rgba), green($rgba), blue($rgba)), $background, alpha($rgba) * 100%);
}
