// stylelint-disable declaration-no-important
// Deprecated in BS 445, keep in Material 2 https://github.com/twbs/bootstrap/pull/30594/commits/a8fed8ca31bdc39dc18713ab9fb0103911f665e2

@mixin bg-variant($class, $color, $color-dark: '') {
  @if ($color-dark != '') {
    a#{$class} {
      @include hover-focus-active {
        background-color: $color-dark !important;
      }
    }
  }

  #{$class} {
    background-color: $color !important;
  }
}
