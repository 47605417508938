// Latest Sliders : https://material.io/components/sliders

@mixin slider-thumb {
  background: $slider-thumb-bg;
  border: $slider-thumb-border;
  border-radius: $slider-thumb-border-radius;
  box-shadow: $slider-thumb-box-shadow;
  height: $slider-thumb-height;
  transition: $slider-thumb-transition;
  width: $slider-thumb-width;
}

@mixin slider-thumb-hover {
  box-shadow: $slider-thumb-hover-box-shadow;
}

@mixin slider-thumb-active {
  background: $slider-thumb-active-bg;
  box-shadow: $slider-thumb-active-box-shadow;
}

@mixin slider-thumb-disabled {
  background: $slider-thumb-bg-disabled;
  box-shadow: $slider-thumb-box-shadow; // Reset active shadow
}

@mixin slider-track {
  background: $slider-track-bg;
  border-color: transparent;
  border-radius: $slider-track-border-radius;
  color: transparent;
  height: $slider-track-height;
  width: $slider-track-width;
}

@mixin slider-track-disabled {
  background: $slider-track-bg-disabled;
  cursor: default;
}

.custom-range {
  appearance: none;
  background: transparent;
  height: $slider-accessibility;
  padding: 0; // Need to reset padding
  width: 100%;

  &:hover {
    cursor: $slider-track-cursor;
  }

  &:focus {
    outline: 0;
  }

  &:active {
    outline: 0;

    &::-webkit-slider-thumb { box-shadow: $slider-thumb-active-box-shadow; }
    &::-moz-range-thumb { box-shadow: $slider-thumb-active-box-shadow; }
    &::-ms-thumb { box-shadow: $slider-thumb-active-box-shadow; }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    @include slider-thumb;

    appearance: none;
    margin-top: ($slider-track-height - $slider-thumb-height) / 2; // Webkit specific

    &:hover {
      @include slider-thumb-hover;
    }

    &:active {
      @include slider-thumb-active;
    }
  }

  &::-webkit-slider-runnable-track {
    @include slider-track;
  }

  &::-moz-range-thumb {
    @include slider-thumb;

    -moz-appearance: none; // stylelint-disable-line property-no-vendor-prefix

    &:hover {
      @include slider-thumb-hover;
    }

    &:active {
      @include slider-thumb-active;
    }
  }

  &::-moz-range-track {
    @include slider-track;
  }

  &::-moz-range-progress {
    background: $slider-thumb-bg;
    border-radius: $slider-track-progress-border-radius;
    height: $slider-track-height-progress;
  }

  &::-ms-thumb {
    @include slider-thumb;

    margin-top: 0; // Edge specific
    margin-right: $slider-thumb-active-box-shadow-width; // Workaround that overflowed box-shadow is hidden.
    margin-left: $slider-thumb-active-box-shadow-width;  // Same comment

    &:hover {
      @include slider-thumb-hover;
    }

    &:active {
      @include slider-thumb-active;
    }
  }

  &::-ms-track {
    @include slider-track;

    background: transparent;
  }

  &::-ms-fill-lower {
    background: $slider-thumb-bg;
    border-radius: $slider-track-progress-border-radius;
    height: $slider-track-height-progress;
  }

  &::-ms-fill-upper {
    background: $slider-track-bg;
    border-radius: $slider-track-border-radius;
    margin-right: 15px; // arbitrary?
  }

  &:disabled {
    cursor: default;

    &::-webkit-slider-thumb {
      @include slider-thumb-disabled;
    }

    &::-webkit-slider-runnable-track {
      @include slider-track-disabled;
    }

    &::-moz-range-thumb,
    &:hover::-moz-range-thumb {
      @include slider-thumb-disabled;
    }

    &::-moz-range-track {
      @include slider-track-disabled;
    }

    &::-moz-range-progress {
      background: $slider-thumb-bg-disabled;
    }

    &::-ms-thumb {
      @include slider-thumb-disabled;
    }

    &::-ms-fill-lower {
      background: $slider-thumb-bg-disabled;
    }

    &::-ms-fill-upper {
      @include slider-track-disabled;
    }
  }
}
