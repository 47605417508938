// Theme palette : https://material.io/design/material-theming/implementing-your-theme.html#color

$primary: (
  color: #6200ee,
  dark:  #3700b3,
  light: #bb86fc
) !default;

$secondary: (
  color: #03dac6,
  dark:  #018786,
  light: #66fff8
) !default;

$danger: (
  color: #b00020,
  dark:  $material-color-red-700,
  light: #c51162
) !default;

$info: (
  color: #714cfe,
  dark:  #021aee,
  light: #d2c2fd
) !default;

$success: (
  color: #61d800,
  dark:  #41c300,
  light: #c6f68d
) !default;

$warning: (
  color: #ff8d00,
  dark:  #ee6002,
  light: #ffc77d
) !default;

$dark: (
  color: $material-color-grey-800,
  dark:  $material-color-grey-900,
  light: $material-color-grey-600
) !default;

$light: (
  color: $material-color-grey-100,
  dark:  $material-color-grey-300,
  light: $material-color-grey-050
) !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    primary:   $primary,
    secondary: $secondary,
    danger:    $danger,
    info:      $info,
    success:   $success,
    warning:   $warning,
    dark:      $dark,
    light:     $light
  ),
  $theme-colors
);

$yiq-contrasted-threshold: 170 !default;
$yiq-text-dark:            $black-primary !default;
$yiq-text-light:           $white-primary !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ('<','%3c'),
  ('>','%3e'),
  ('#','%23'),
) !default;
