// stylelint-disable declaration-no-important

@if $enable-print-styles {
  @media print {
    *,
    *::before,
    *::after {
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a:not(.btn) {
      //text-decoration: underline;
    }

    // stylelint-disable
    abbr[title]::after {
      content: ' ("attr(title) ") ';
    }
    // stylelint-enable

    pre {
      white-space: pre-wrap !important;
    }

    pre,
    blockquote {
      border: $border-width solid rgba($black, 0.12);
      page-break-inside: avoid;
    }

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-inside: avoid;
    }

    // stylelint-disable
    @page {
      size: $print-page-size;
    }
    // stylelint-enable

    body {
      min-width: $print-body-min-width !important;
    }

    .container {
      min-width: $print-body-min-width !important;
    }

    .fab-actions,
    .navbar,
    .tooltip {
      display: none;
    }

    .badge {
      border: $border-width solid $black;
    }

    .table {
      border-collapse: collapse !important;

      td,
      th {
        background-color: $white !important;
      }
    }

    .table-bordered {
      th,
      td {
        border: 1px solid $border-color-solid;
      }
    }

    .table-dark {
      color: inherit;

      th,
      td,
      thead th,
      tbody + tbody {
        border-color: $border-color-solid;
      }
    }

    .table .thead-dark th {
      border-color: $border-color-solid;
      color: inherit;
    }

    // ADDITIONS

    .card {
      border: $border-width solid $border-color-solid;
      page-break-inside: avoid;
    }

    .dropdown-menu {
      border: $border-width solid $border-color-solid;
    }

    .list-group-item {
      border: $border-width solid $border-color-solid;
      margin-bottom: ($border-width * -1);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .list-group-flush {
      &:first-child .list-group-item:first-child {
        border-top: 0;
      }

      &:last-child .list-group-item:last-child {
        border-bottom: 0;
      }

      .list-group-item {
        border-right: 0;
        border-left: 0;
      }
    }

    .nav-tabs {
      border-bottom: $border-width solid $border-color-solid;
    }

    .popover {
      border: $border-width solid $border-color-solid;
    }
  }
}
