// Only display content to screen readers
//
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

@mixin sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin sr-only-focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    overflow: visible;
    position: static;
    white-space: normal;
    width: auto;
  }
}
