.tooltip {
  font-size: $tooltip-font-size;
  font-weight: $tooltip-font-weight;
  margin: $tooltip-margin;
  max-width: 250px;
  opacity: 0;
  position: absolute;
  z-index: $tooltip-zindex;

  @include media-breakpoint-up($tooltip-breakpoint) {
    font-size: $tooltip-font-size-desktop;
    margin: $tooltip-margin-desktop;
  }

  &.show {
    opacity: $tooltip-opacity;

    .tooltip-inner {
      transform: scale(1);
      transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

.tooltip-inner {
  @include text-truncate;

  background-color: $tooltip-bg;
  border-radius: $tooltip-border-radius;
  color: $tooltip-color;
  height: $tooltip-height; // 32px
  letter-spacing: $letter-spacing-caption;
  line-height: $tooltip-height;
  padding: $tooltip-padding-y $tooltip-padding-x;
  transform: scale($tooltip-scale);
  transform-origin: center top;
  transition: transform 75ms $transition-timing-function-acceleration;

  @include media-breakpoint-up($tooltip-breakpoint) {
    height: $tooltip-height-desktop; // 24px
    line-height: $tooltip-height-desktop;
    padding: $tooltip-padding-y-desktop $tooltip-padding-x-desktop;
  }
}
