// TODO : copy label position

.floating-label {
  padding-top: $floating-label-font-size;
  position: relative;

  &.has-value label,
  &.is-focused label {
    top: 0;
    transform: scale($floating-label-font-size / $textfield-font-size);
  }

  &:not(.has-value):not(.is-focused) .form-control {
    &[type='date'],
    &[type='datetime-local'],
    &[type='time'] {
      color: transparent;
    }
  }

  label {
    //@include transition-standard(color, top, transform);
    @include text-truncate;

    color: $floating-label-color;
    font-size: $textfield-font-size;
    letter-spacing: $letter-spacing-subtitle-1; // addition
    line-height: 1.15; //$textfield-line-height;
    margin: 0;
    max-width: 98%;
    padding: 0;
    position: absolute;
    top: ($floating-label-font-size + $textfield-padding-y);
    left: 0;
    transform-origin: left top;//0 0;
    transition:
      transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.15s cubic-bezier(0.4, 0, 0.2, 1),
      top 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  }

  %form-control {
    position: relative;

    &:focus {
      &::placeholder {
        color: $textfield-hint-color;
        opacity: 1; /* needed for Firefox */
      }
    }

    &::placeholder {
      @include transition-standard(opacity);

      color: transparent;
      opacity: 0;
    }
  }
}

// Size

.floating-label-lg {
  &.has-value label,
  &.is-focused label {
    transform: scale($floating-label-font-size-lg / $textfield-font-size-lg);
  }

  label {
    font-size: $textfield-font-size-lg;
    line-height: $textfield-line-height-lg;
    top: ($floating-label-font-size-lg + $textfield-padding-y-lg);
  }

  %form-control {
    font-size: $textfield-font-size-lg;
    line-height: $textfield-line-height-lg;
    padding: $textfield-padding-y-lg $textfield-padding-x-lg calc(#{$textfield-padding-y-lg} - #{$textfield-border-width});
  }

  %form-select {
    padding-right: $textfield-select-bg-size;
  }

  %form-textarea {
    padding: $textfield-box-padding-y-lg $textfield-box-padding-x-lg calc(#{$textfield-box-padding-y-lg} - #{$textfield-border-width});
  }
}

.floating-label-sm {
  &.has-value label,
  &.is-focused label {
    transform: scale($floating-label-font-size-sm / $textfield-font-size-sm);
  }

  label {
    font-size: $textfield-font-size-sm;
    line-height: $textfield-line-height-sm;
    top: ($floating-label-font-size-sm + $textfield-padding-y-sm);
  }

  %form-control {
    font-size: $textfield-font-size-sm;
    line-height: $textfield-line-height-sm;
    padding: $textfield-padding-y-sm $textfield-padding-x-sm calc(#{$textfield-padding-y-sm} - #{$textfield-border-width});
  }

  %form-select {
    padding-right: $textfield-select-bg-size;
  }

  %form-textarea {
    padding: $textfield-box-padding-y-sm $textfield-box-padding-x-sm calc(#{$textfield-box-padding-y-sm} - #{$textfield-border-width});
  }
}

// Floating label with textfield box

.floating-label.textfield-box {
  padding-top: 0;

  &.has-value label,
  &.is-focused label {
    line-height: 1;
    top: $textfield-box-label-spacer-y;
  }

  label {
    color: $floating-label-color-box;
    top: $textfield-box-padding-y;
    left: $textfield-box-padding-x;
  }

  %form-control,
  %form-textarea {
    padding-top: ($textfield-box-height - $textfield-box-label-spacer-y - $textfield-font-size * $textfield-line-height);
  }

  %form-control {
    padding-bottom: calc(#{$textfield-box-label-spacer-y} - #{$textfield-border-width});
  }
}

// label color on focus
.floating-label.is-focused label {
  color: $floating-label-color-focus;
}

.floating-label-lg.textfield-box {
  label {
    top: $textfield-box-padding-y-lg;
    left: $textfield-box-padding-x-lg;
  }

  %form-control,
  %form-textarea {
    padding-top: ($textfield-box-height-lg - $textfield-box-label-spacer-y - $textfield-font-size-lg * $textfield-line-height-lg);
    padding-right: $textfield-box-padding-x-lg;
    padding-left: $textfield-box-padding-x-lg;
  }

  %form-select {
    padding-right: $textfield-select-bg-size;
  }
}

.floating-label-sm.textfield-box {
  label {
    top: $textfield-box-padding-y-sm;
    left: $textfield-box-padding-x-sm;
  }

  %form-control,
  %form-textarea {
    padding-top: ($textfield-box-height-sm - $textfield-box-label-spacer-y - $textfield-font-size-sm * $textfield-line-height-sm);
    padding-right: $textfield-box-padding-x-sm;
    padding-left: $textfield-box-padding-x-sm;
  }

  %form-select {
    padding-right: $textfield-select-bg-size;
  }
}

// Ripple

@if ($enable-form-ripple) {
  .floating-label.form-ripple::before {
    top: calc(#{$floating-label-font-size + ($textfield-padding-y * 2) + ($textfield-line-height * $textfield-font-size)} - #{(2*$textfield-border-width)});
  }

  .floating-label-lg.form-ripple::before {
    top: calc(#{$floating-label-font-size + ($textfield-padding-y-lg * 2) + ($textfield-line-height-lg * $textfield-font-size-lg)} - #{(2*$textfield-border-width)});
  }

  .floating-label-sm.form-ripple::before {
    top: calc(#{$floating-label-font-size + ($textfield-padding-y-sm * 2) + ($textfield-line-height-sm * $textfield-font-size-sm)} - #{(2*$textfield-border-width)});
  }
}
