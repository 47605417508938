$transition-scale: 0.87 !default;

// The movement of an element is based on https://material.io/design/motion

// Transition duration
// Based on https://material.google.com/motion/duration-easing.html#duration-easing-common-durations

// Accelerate easing : Elements exiting a screen use acceleration easing, where they start at rest and end at peak velocity.

$transition-duration-desktop:          280ms !default;
$transition-duration-desktop-complex:  250ms !default;
$transition-duration-desktop-entering: 150ms !default;
$transition-duration-desktop-leaving:  75ms !default;

$transition-duration-mobile:          300ms !default;
$transition-duration-mobile-complex:  375ms !default;
$transition-duration-mobile-entering: 225ms !default;
$transition-duration-mobile-leaving:  195ms !default;

$transition-duration-tablet:          390ms !default;
$transition-duration-tablet-complex:  487.5ms !default;
$transition-duration-tablet-entering: 292.5ms !default;
$transition-duration-tablet-leaving:  253.5ms !default;

$transition-duration-control:         100ms !default;

// Transition timing function
// Based on https://material.io/design/motion/speed.html#easing

$transition-timing-function-acceleration: cubic-bezier(0.4, 0, 1, 1) !default;
$transition-timing-function-deceleration: cubic-bezier(0, 0, 0.2, 1) !default;
$transition-timing-function-sharp:        cubic-bezier(0.4, 0, 0.6, 1) !default;
$transition-timing-function-standard:     cubic-bezier(0.4, 0, 0.2, 1) !default;
