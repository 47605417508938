// Inspired by https://dev.to/dip15739/dark-mode-with-only-1-css-property-17fl
// stylelint-disable selector-no-qualifying-type

// Use mixin because @extend cannot be used inside @media
@mixin dark-theme() {
  body,
  label {
    font-weight: 500;
  }

  img,
  video,
  .prevent-dark-theme {
    filter: invert(1) hue-rotate(180deg);
  }
}

html[data-theme='dark'] {
  @include dark-theme();

  filter: invert(1) hue-rotate(180deg);
}

body {
  transition: color 300ms, background-color 300ms;
}

@if ($enable-dark-theme-media-query) {
  @media (prefers-color-scheme: dark) {
    @include dark-theme();

    html {
      filter: invert(1) hue-rotate(180deg);
    }
  }
}
