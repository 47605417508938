@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-truncate-flex {
  @include text-truncate;

  flex: 1;
}
