// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  @include border-radius($badge-border-radius);
  //@include transition($badge-transition);

  align-items: center;
  display: inline-flex;
  font-size: inherit; //@include font-size($badge-font-size);
  font-weight: $badge-font-weight;
  justify-content: center;
  line-height: inherit;
  padding-right: $badge-padding-x;
  padding-left: $badge-padding-x;
  //text-align: center;
  white-space: nowrap;

  @at-root a#{&} {
    @include hover-focus {
      text-decoration: none;
    }
  }

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }

  // Material addition : https://material.angular.io/components/badge/examples
  .btn > &,
  .position-relative > & {
    border-radius: $badge-width / 2;
    // display: inline-block;
    font-family: Roboto, sans-serif;
    font-size: $font-size-caption;
    font-weight: $badge-btn-font-weight;
    height: $badge-width;
    letter-spacing: initial;
    // line-height: $badge-width;
    min-width: $badge-width;
    position: absolute;
    top: -$badge-width / 2;
  }

  .btn > &,
  i > &,
  [class*='material-icons'] > & {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0%;

    &.badge-before {
      right: auto;
      left: 0;
      transform: translate(-50%, -50%);
      transform-origin: 0% 100%;
    }
  }
}

// Pill badges with extra rounded corners

.badge-pill {
  @include border-radius(1em);

  //padding-right: $badge-pill-padding-x;
  //padding-left: $badge-pill-padding-x;
}

@each $color, $values in $theme-colors {
  .badge-#{$color} {
    background-color: theme-color($color);
    color: color-yiq(theme-color($color));

    &[href] {
      @include hover-focus-active {
        background-color: theme-color-dark($color);
        color: color-yiq(theme-color-dark($color));
        text-decoration: none;
      }
    }
  }
}

// @mixin badge-variant not used
