.fade {
  //@include transition-standard(opacity);
  transition: opacity $transition-duration-desktop-complex $transition-timing-function-standard;

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  @include transition-standard(height);

  height: 0;
  overflow: hidden;
  position: relative;
}

// stylelint-disable-next-line selector-no-qualifying-type
tbody.collapse.show {
  display: table-row-group;
}

// stylelint-disable-next-line selector-no-qualifying-type
tr.collapse.show {
  display: table-row;
}
