// stylelint-disable declaration-no-important

//
// Text
//

.text-monospace { font-family: $font-family-monospace !important; }

// Alignment

.text-justify { text-align: justify !important; }
.text-wrap { white-space: normal !important; }
.text-nowrap { white-space: nowrap !important; }
.text-truncate { @include text-truncate; }
.text-truncate-flex { @include text-truncate-flex; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left { text-align: left !important; }
    .text#{$infix}-right { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

// Transformation

.text-lowercase { text-transform: lowercase !important; }
.text-uppercase { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

// Weight and italics

.font-weight-light { font-weight: $font-weight-light !important; }
.font-weight-lighter { font-weight: $font-weight-lighter !important; }
.font-weight-normal { font-weight: $font-weight-normal !important; }
.font-weight-bold { font-weight: $font-weight-bold !important; }
.font-weight-bolder { font-weight: $font-weight-bolder !important; }
.font-italic { font-style: italic !important; }

// Contextual colors

@include text-emphasis-variant('.text-black', $black);
@include text-emphasis-variant('.text-black-primary', $black-primary);
@include text-emphasis-variant('.text-black-secondary', $black-secondary);
@include text-emphasis-variant('.text-black-hint', $black-hint);
@include text-emphasis-variant('.text-black-divider', $black-divider);
@include text-emphasis-variant('.text-white', $white);
@include text-emphasis-variant('.text-white-primary', $white-primary);
@include text-emphasis-variant('.text-white-secondary', $white-secondary);
@include text-emphasis-variant('.text-white-hint', $white-hint);
@include text-emphasis-variant('.text-white-divider', $white-divider);
@include text-emphasis-variant('.text-muted', $text-muted);

@each $color, $values in $theme-colors {
  @include text-emphasis-variant('.text-#{$color}', theme-color($color), theme-color-dark($color));
}

.text-body { color: $body-color !important; }
.text-black-50 { color: rgba($black, $black-secondary-opacity) !important; }
.text-white-50 { color: rgba($white, $white-hint-opacity) !important; }

// Misc

.text-hide {
  @include text-hide($ignore-warning: true);
}

.text-decoration-none { text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

// Reset

.text-reset { color: inherit !important; }
