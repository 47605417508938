/*!
 * Daemonite Material UI 4.6.0 - RC2 (https://djibe.github.io/material/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Material UI by djibe, Maya, Geoff Bowers and Alex Skinner
 */

@import 'colors';
@import 'functions';
@import 'variables';
@import 'mixins';

// Base CSS

@import 'base/base'; // root + reboot
@import 'base/grid';
@import 'base/typography'; // type

// Bootstrap components
// Components covered in Bootstrap's css but not in Material design

@import 'bootstrap/alert';
@import 'bootstrap/badge';
@import 'bootstrap/breadcrumb';
@import 'bootstrap/carousel';
@import 'bootstrap/close';
@import 'bootstrap/code';
@import 'bootstrap/forms';
@import 'bootstrap/images';
@import 'bootstrap/jumbotron';
@import 'bootstrap/media';
@import 'bootstrap/nav';
@import 'bootstrap/pagination';
@import 'bootstrap/popover';
@import 'bootstrap/responsive-embed';
@import 'bootstrap/transitions';

// Material components
// Components covered in Material design (https://material.google.com/components)

@import 'material/buttons';
@import 'material/button-flat';
@import 'material/button-float';
@import 'material/button-group';
@import 'material/card';
@import 'material/chip';
@import 'material/dark-theme';
@import 'material/data-table'; // tables
@import 'material/dialog'; // modal
@import 'material/expansion-panel'; // list-group
@import 'material/menu'; // dropdown
@import 'material/navdrawer';
@import 'material/picker';
@import 'material/progress';
@import 'material/progress-circular'; // spinners
@import 'material/selection-control'; // custom-forms
@import 'material/sliders';
@import 'material/snackbars'; // toasts
@import 'material/stepper';
@import 'material/tab';
@import 'material/text-field';
@import 'material/text-field-box';
@import 'material/text-field-floating-label';
@import 'material/text-field-input-group'; // input-group
@import 'material/toolbar'; // navbar
@import 'material/tooltip';

// Utilities

@import 'utilities';

// Very basic print styles

@import 'print';
